import Mapgen from "./mapgen";

class DreadkeepMain {

  constructor() {
    this.generatedMap = null;
  }


  InitMap(){
    console.log("Init map");
    let mapGen = new Mapgen(50, 100, 10);
    return mapGen;;
  }
  

}

export default DreadkeepMain;