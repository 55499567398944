import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as PIXI from 'pixi.js'
import DreadkeepMain from './dreadkeep_main';
const app = new PIXI.Application({ width: 800, height: 600 });

document.body.appendChild(app.view);

const dreadkeepMain = new DreadkeepMain();
const generatedMap = dreadkeepMain.InitMap();


generatedMap.generatedRooms.forEach(room => {

	var roomRect = new PIXI.Graphics();

	roomRect.beginFill(0xFFFF00);
	roomRect.alpha = 0.5;

	// set the line style to have a width of 5 and set the color to red
	roomRect.lineStyle(5, 0xFF0000);

	// draw a rectangle
	roomRect.drawRect(room.x1, room.y1, room.sizeX, room.sizeY);

	roomRect.interactive = true;
	// create hit area, needed for interactivity
	roomRect.hitArea = new PIXI.Rectangle(room.x1, room.y1, room.sizeX, room.sizeY);


	// make circle non-transparent when mouse is over it
	roomRect.mouseover = function(mouseData) {
		console.log("MOUSE OVER");
		this.alpha = 1;
	}

	// make circle half-transparent when mouse leaves
	roomRect.mouseout = function(mouseData) {
		this.alpha = 0.5;
	}

	roomRect.click= function(mouseData) {
		console.log(room.centerPoint);
	}


	//let texture = new PIXI.Texture(loader.resources["dreadkeep_resources/textures/dungeon_tiles.png"].texture, new PIXI.Rectangle(32, 32, 32, 32));

	app.stage.addChild(roomRect);

 });


 app.stage.addChild(generatedMap.minimalSpanningLines);


 console.log(app.stage.position);

 // start animating
animate();

function animate() {
  requestAnimationFrame(animate);
  // render the root container
  app.render(app.stage);
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
