import Delaunator from 'delaunator';
import * as PIXI from 'pixi.js'

class Mapgen {


	constructor(dungeonSize, cellsToGenerate, scale) {
		this.dungeonScale = scale;
		this.generatedRooms = this.GenerateMapNew(dungeonSize, cellsToGenerate, this.dungeonScale);
		this.roomCenterPoints = this.GetAllRoomCenterPoints(this.generatedRooms);
		this.delaunay = this.DelaunayGeneration(this.roomCenterPoints);
		this.delaunayCoordinates = 	this.CreateTriangleCoordinates(this.delaunay.triangles, this.roomCenterPoints);
		//this.GetConnectedPoints(new Point(0,0), this.delaunayCoordinates); 
		//this.delaunayLines = this.CreateTriangleLineMeshes(this.delaunayCoordinates);
		this.minimalSpanningTree = this.CreateMinimalSpanningTree(this.delaunay, this.generatedRooms);
		this.minimalSpanningLines = this.DrawMinimalSpanningTree(this.minimalSpanningTree);
		this.generatedCorridors = this.GenerateCorridors(this.minimalSpanningTree);
	}


	//dungeonSize - a value which determines how large of an area to generate within
	//cellsToGenerate - How many iterations to perform while placing rooms
	//scale - How large to scale the rooms for pixel size
    GenerateMapNew(dungeonSize, cellsToGenerate, scale) {

    	let generatedRooms = [];

    	//Start room generation 
    		
    	//Math.seedrandom('zzzzz');
    	for (let i = 0; i < cellsToGenerate; i++) {

    		let roomSizeX = (Math.floor(Math.random() * 6) + 1) * scale; //Add 1 so size is never zero
    		let roomSizeY = (Math.floor(Math.random() * 6) + 1) * scale; //Add 1 so size is never zero
    		let roomLocationX = (Math.floor(Math.random() * dungeonSize)) * scale;
    		let roomLocationY = (Math.floor(Math.random() * dungeonSize)) * scale;
    		let generatedRoom = this.GenerateRoom(roomSizeX, roomSizeY, roomLocationX, roomLocationY);

    		//Check for overlap
    		
    		var validPlacement = true;

    		//Check for overlap
    		generatedRooms.forEach(existingRoom => {

    			//console.log("Running for each");
    			if (this.RoomOverlaps(generatedRoom, existingRoom) == true || this.RoomContains(generatedRoom, existingRoom) == true || this.RoomTouches(generatedRoom, existingRoom) == true) {
    				//console.log("OVERLAP DETECTED");
    				validPlacement = false;
    			} //This fails as we are adding to the array
    		});

    		//If there is no overlap, place the room and start the next room generation iteration
    		if (validPlacement == true) {
    			generatedRooms.push(generatedRoom);
    		}
    	}

    	//let delaunayTriangles = this.DelaunayGeneration(generatedRooms);

    	return generatedRooms;

    }
	
	GenerateRoom(xSize, ySize, xLocation, yLocation) {

	  //console.log("Making room at: " + xLocation + ", " + yLocation + " with size: " + xSize + ", " + ySize);
      return new Room(xSize, ySize, xLocation, yLocation);
	}

	GenerateCorridors(roomConnections) {

	}


	// Check if rectangle a contains rectangle b
	// Each object (a and b) should have 2 properties to represent the
	// top-left corner (x1, y1) and 2 for the bottom-right corner (x2, y2).
	RoomContains(a, b) {
		return !(
			b.x1 < a.x1 ||
			b.y1 < a.y1 ||
			b.x2 > a.x2 ||
			b.y2 > a.y2
		);
	}

	// Check if rectangle a overlaps rectangle b
	// Each object (a and b) should have 2 properties to represent the
	// top-left corner (x1, y1) and 2 for the bottom-right corner (x2, y2).
	RoomOverlaps(a, b) {
		// no horizontal overlap
		if (a.x1 >= b.x2 || b.x1 >= a.x2) return false;

		// no vertical overlap
		if (a.y1 >= b.y2 || b.y1 >= a.y2) return false;

		return true;
	}

	// Check if rectangle a touches rectangle b
	// Each object (a and b) should have 2 properties to represent the
	// top-left corner (x1, y1) and 2 for the bottom-right corner (x2, y2).
	RoomTouches(a, b) {
		// has horizontal gap
		if (a.x1 > b.x2 || b.x1 > a.x2) return false;

		// has vertical gap
		if (a.y1 > b.y2 || b.y1 > a.y2) return false;

		return true;
	}

	GetAllRoomCenterPoints(generatedRooms){
		const points = new Array(); 
		generatedRooms.forEach(room => {
			//points.push([room.centerX, room.centerY]);
			points.push(room.centerPoint);
		});

		//console.log(points);
		return points;
	}

	//Create Graph using Delaunay Triangulation to connect rooms
	DelaunayGeneration(centerPoints){

		let formattedCenterPoints = new Array(); 

		centerPoints.forEach(point => {
			formattedCenterPoints.push([point.x, point.y]);
		});


		const delaunay = Delaunator.from(formattedCenterPoints);
		return delaunay;
	} 



	nextHalfedge(e) { return (e % 3 === 2) ? e - 2 : e + 1; }
    prevHalfedge(e) { return (e % 3 === 0) ? e + 2 : e - 1; }

	CreateTriangleCoordinates(triangles, points){
		//create a blue LineBasicMaterial


		//const triangles = new Array();
		let coordinates = new Array();

		for (let i = 0; i < triangles.length; i += 3) {

			//console.log(points[triangles[i]]);

			
		    coordinates.push([
		        points[triangles[i]],
		        points[triangles[i + 1]],
		        points[triangles[i + 2]]
		    ]);
		    
		}

		return coordinates;

	}

	CreateTriangleLineMeshes(coordinates){

		/*
		const material = new THREE.LineBasicMaterial( { color: 0x0000ff } );
		this.triangleLines = new THREE.Group();

		coordinates.forEach(coord => {
			//console.log(coord);
			let trianglePoints = new Array();
			trianglePoints.push(new THREE.Vector3(coord[0].x, 0, coord[0].y)); //Set the X and Y point in the scene 
			trianglePoints.push(new THREE.Vector3(coord[1].x, 0, coord[1].y)); //Set the X and Y point in the scene 
			trianglePoints.push(new THREE.Vector3(coord[2].x, 0, coord[2].y)); //Set the X and Y point in the scene 
			//console.log(trianglePoints);
			const geometry = new THREE.BufferGeometry().setFromPoints( trianglePoints );
			const line = new THREE.Line( geometry, material );
			this.triangleLines.add(line);
		});

		return this.triangleLines;
		*/

	}


	
	GetConnectedPoints(point, points, delaunay) {
		var endPoints = new Array();
		for (let e = 0; e < delaunay.triangles.length; e++) {
			let trianglePoint = new Point(points[delaunay.triangles[e]][0], points[delaunay.triangles[e]][1]); //Convert our point into a "Point" object defined below
	        if (trianglePoint.equals(point)) { //If this triangle point is equal to the one we are checking against, get end points
	        	let endPoint = new Point( points[delaunay.triangles[this.nextHalfedge(e)]][0], points[delaunay.triangles[this.nextHalfedge(e)]][1]);
	        	//console.log(endPoint);
	           // endPoints.push(points[delaunay.triangles[this.nextHalfedge(e)]]); //Push the end point into the end points and start next iteration 
	            endPoints.push(endPoint); //Push the end point into the end points and start next iteration 
	        }
	    }

	    //console.log(endPoints);
	    return endPoints; //Return end points for given point  
	}

	//Using prims algorithm
	CreateMinimalSpanningTree(delaunay, rooms) {

		let reached = new Array(); //Used to keep track of what rooms we have reached 
		let not_reached = rooms.slice(); //Create a copy of the rooms array to avoid referencing issues
		let connections = new Array(); //Connections is an array of [[point, point]] so we know where to draw our spanning tree lines 

		//Convert room center points to format that is understood by delanator library 
		let formattedCenterPoints = new Array(); 

		rooms.forEach(room => {
			formattedCenterPoints.push([room.centerPoint.x, room.centerPoint.y]);
		});


		reached.push(rooms[0]); //Add arbitrary starting position
		not_reached.splice(0, 1); //Remove it from unreached


        //console.log("SHOWING FIRST CENTER POINT");
		//console.log(roomCenterPoints[0]);


        
		while (not_reached.length > 0) {

			var minDistance = Infinity;
			var reachedIndex = null;
			var endPointIndex = null;

			//var startPoint = null;
			//var endPoint = null;

			let endPoints = new Array();

			for (let i = 0; i < reached.length; i++) {
				endPoints = this.GetConnectedPoints(reached[i].centerPoint, formattedCenterPoints, delaunay); //Get all end points for this reached point 

				//Translate the found end points into the appropriate unreached points 
				let unreachedIndexes = new Array();
				endPoints.forEach(element => {
					let uIndex = this.FindPointInArray(element, not_reached);

					///If we can't find the end point in unreached, it will return -1 as it means its already been reached and we can skip it.
					if (uIndex != -1) {
						unreachedIndexes.push(this.FindPointInArray(element, not_reached));
					}
				});

				unreachedIndexes.forEach(uIndex => {

					//console.log("NOT REACHED INDEX:");
					//console.log(not_reached[uIndex]);
				
					let newDistance = this.GetDistance(reached[i].centerPoint, not_reached[uIndex].centerPoint);
					if(newDistance < minDistance) {
						minDistance = newDistance;
						reachedIndex = i;
						endPointIndex = uIndex;
					}
				});


			}

	
			let startRoom = reached[reachedIndex];
			let endRoom = not_reached[endPointIndex];
			//console.log(endPoint);

			//console.log("STart room");
			//console.log(startRoom);
		
			//If we have more than 1 reached, start adding connections
			connections.push([startRoom, endRoom]); //Add the latest added two added reach to form line connections

			reached.push(endRoom);


			
			console.log("Room count:" + rooms.length);

			//let unreachedIndex = this.FindPointInArray(endPoint, not_reached);
			//console.log("Removing at index: " + unreachedIndex);
			not_reached.splice(endPointIndex, 1);
			

			
		}

		return connections;
	}

	//Returns the index of a point in a point array.
	//Returns -1 if not found.
	FindPointInArray(point, pointArray) {
		for (let i = 0; i < pointArray.length; i++) {
			if (pointArray[i].centerPoint.equals(point)) {
				return i;
			}
		}
		return -1;
	}


	GetDistance (point1, point2) {
		//console.log(point1);
		//console.log(point2);
		var a = point1.x - point2.x;
		var b = point1.y - point2.y;

		var dist = Math.abs(Math.sqrt( a*a + b*b ));
		//console.log(dist);
	  	return (dist);
	}

	



	DrawMinimalSpanningTree(rooms) {
		
		let lineContainer = new PIXI.Container();

		rooms.forEach(room => {
			let spanningLine = new PIXI.Graphics();
			// Move it to the beginning of the line
			//spanningLine.position.set(room[0].centerPoint.x, room[0].centerPoint.y);
			spanningLine.lineStyle(1, 0xffffff)
      		 .moveTo(room[0].centerPoint.x, room[0].centerPoint.y)
       		.lineTo(room[1].centerPoint.x, room[1].centerPoint.y);
			
			lineContainer.addChild(spanningLine);
		});

		return lineContainer;

		/*
		const material = new THREE.LineBasicMaterial( { color: 0xff0000 } );
		this.spanningTreeLines = new THREE.Group();

		rooms.forEach(room => {
			let spanningPoints = new Array();
			spanningPoints.push(new THREE.Vector3(room[0].centerPoint.x, 0, room[0].centerPoint.y)); //Set the X and Y point in the scene 
			spanningPoints.push(new THREE.Vector3(room[1].centerPoint.x, 0, room[1].centerPoint.y)); //Set the X and Y point in the scene 
			const geometry = new THREE.BufferGeometry().setFromPoints( spanningPoints );
			const line = new THREE.Line( geometry, material );
			this.spanningTreeLines.add(line);
		});

		return this.spanningTreeLines;
		*/
	}
		

}


class Room {
	constructor(roomSizeX, roomSizeY, xPos, yPos) {
		this.sizeX = roomSizeX;
		this.sizeY = roomSizeY;


		this.x1 = xPos - 0.5;
		this.y1 = yPos - 0.5;

		this.x2 = xPos + roomSizeX - 0.5;
		this.y2 = yPos + roomSizeY - 0.5;

		this.centerPoint = new Point((this.x1 + this.x2) / 2, (this.y1 + this.y2) / 2)
		//this.centerX = (this.x1 + this.x2) / 2;
		//this.centerY = (this.y1 + this.y2) / 2;


		//this.roomFloorTiles = new THREE.Group();


		console.log("Creating room of size: " + this.sizeX + ", " + this.sizeY);
		//console.log("Center Is: " + this.centerX + ", " + this.centerY);
		//console.log("x1 is: " + this.x1);

		  /*
	  const stoneFloorTexture = new THREE.TextureLoader().load( 'dreadkeep_resources/textures/paving_2.png' );
      stoneFloorTexture.wrapS = THREE.RepeatWrapping;
      stoneFloorTexture.wrapT = THREE.RepeatWrapping;
      stoneFloorTexture.repeat.set(1,1);

      const floorMaterial = new THREE.MeshBasicMaterial( { map: stoneFloorTexture, side: THREE.DoubleSide } );
      const geometry = new THREE.PlaneGeometry(1, 1);
     */
	  //geometry.computeBoundingBox();
	  //geometry.applyMatrix( new THREE.Matrix4().makeTranslation(0.5 * roomSizeX, 0, 0.5 * roomSizeY) );
      //Shift vertices so that the corner is the pivot point of the tile:
      //geometry.applyMatrix( new THREE.Matrix4().setTranslation( -this.sizeX / 2 , 0, this.sizeY / 2) );

      //const material = new THREE.MeshBasicMaterial( { color: 0x0000FF } );


      /*
      for (let i = xPos; i < roomSizeX +xPos; i++) {
      	for (let j = yPos; j < roomSizeY +yPos; j++) {

      		//console.log("Setting tile at position: " + i + ", " + j);
      		let floorMesh = new THREE.Mesh( geometry, floorMaterial );
      		floorMesh.rotation.x -= Math.PI / 2;
      		floorMesh.position.y = 0;
      		floorMesh.position.x = i;
      		floorMesh.position.z = j; //Z position in the 3d space is actually the Y axis of our "2d grid"
      		this.roomFloorTiles.add(floorMesh);
      		//this.dungeonArray[i][j]

      	}
      }
	  */

      //console.log("Floor tile length: " + this.roomFloorTiles.length);

      /*
      this.floorMesh = new THREE.Mesh( geometry, floorMaterial );
      this.floorMesh.rotation.x -= Math.PI / 2;
      this.floorMesh.position.y = -0.5;
      this.floorMesh.position.x = this.centerX;
      this.floorMesh.position.z = this.centerY; //Z position in the 3d space is actually the Y axis of our "2d grid"
	  */

      //this.pivot = new THREE.Group();
      //this.pivot.position.x = this.x;
      //this.pivot.position.y = -0.5;
      //this.pivot.position.z = this.y;
	  //this.pivot.add( this.floorMesh );

      //Put red dots on corners of room to help figure out room positions for debug purposes 
  
      /*
      const sphereGeometry = new THREE.SphereGeometry(0.1, 32, 16);
      const sphereMaterial = new THREE.MeshBasicMaterial ( {color: 0xFF0000});
      this.dotMesh_1 = new THREE.Mesh(sphereGeometry, sphereMaterial);
      this.dotMesh_2 = new THREE.Mesh(sphereGeometry, sphereMaterial);
      this.dotMesh_3 = new THREE.Mesh(sphereGeometry, sphereMaterial);
      this.dotMesh_4 = new THREE.Mesh(sphereGeometry, sphereMaterial);
      this.dotMesh_center = new THREE.Mesh(sphereGeometry, sphereMaterial);

	  
      this.dotMesh_1.position.set(this.x1, -0.5, this.y1);
      this.dotMesh_2.position.set(this.x2, -0.5, this.y1);
      this.dotMesh_3.position.set(this.x1, -0.5, this.y2);
      this.dotMesh_4.position.set(this.x2, -0.5, this.y2);
      this.dotMesh_center.position.set(this.centerPoint.x, 0, this.centerPoint.y);
	  */
      

      /*
      this.dotMesh_1.position.set(this.floorMesh.geometry.boundingBox.min.x, -0.5, this.floorMesh.geometry.boundingBox.min.z);
      this.dotMesh_2.position.set(this.floorMesh.geometry.boundingBox.max.x, -0.5, this.floorMesh.geometry.boundingBox.min.z);
      this.dotMesh_3.position.set(this.floorMesh.geometry.boundingBox.min.x, -0.5, this.floorMesh.geometry.boundingBox.max.z);
      this.dotMesh_4.position.set(this.floorMesh.geometry.boundingBox.max.x, -0.5, this.floorMesh.geometry.boundingBox.max.z);
      */

		//mesh.geometry.boundingBox.min.x
		//mesh.geometry.boundingBox.min.z
		//mesh.geometry.boundingBox.max.x
		//mesh.geometry.boundingBox.max.z
      //this.dotMesh_center.position.set(this.x1, 0, this.centerY);


      //console.log(this.dotMesh_1.position.x);

	}
}

class Point {
 
	constructor(xPos, yPos) {
		this.x = xPos;
		this.y = yPos;
	}

	equals(second_point) {
		return this.x == second_point.x && this.y == second_point.y;
	}
}

/*
class Triangle {
	constructor(point1, point2, point3) {
		this.point1 = point;
		this.point2 = point2;
		this.point3 = point3;
	}
}
*/

export default Mapgen;